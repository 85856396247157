import React from "react";
const Footer = () => {
    return (
<>
    <div className="dasboard-footer">
    ©&nbsp;2024, CONTINGENT FITNESS TRAINING, INC. All Rights Reserved.
    </div>
</>

)
}
export default Footer