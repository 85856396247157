import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../config";
import Skeleton from "react-loading-skeleton";
import './PopUp.css';
import adminDumny from "../../assets/images/adminDumny.svg";
const EditProfilebyUsrPopUp = ({ handlePopUp }) => {
  const [loading, setLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [error, setError] = useState('');
  const[buthide,setButhide]=useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    age: "",
    gender: "",
    weight: "",
    height: ""
  });
  const [userForm, setUserForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    age: "",
    gender: "",
    weight: "",
    height: ""
  });
  const [profileImage, setProfileImage] = useState(null);
  const [imageURL, setImageURL] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getProfile();
    fetchProfileImage();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserForm({
      ...userForm,
      [name]: value
    });
  };

  const handleGenderChange = (e) => {
    setUserForm({
      ...userForm,
      gender: e.target.value
    });
  };

  const handleImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const updateProfile = (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    const fullName = `${userForm.firstName} ${userForm.lastName}`;

    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/user/profile`,
      method: 'POST',
      data: {
        ...userForm,
        name: fullName
      }
    }).then((res) => {
      setLoading(false);
      getProfile();
      handlePopUp(0)
      if(res.data.data.name!==localStorage.getItem('user-name')){
        localStorage.setItem("user-name",res.data.data.name)
      }
    }).catch((err) => {
      if (err?.response?.data?.message) {
        setError(err?.response?.data?.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
      setLoading(false);
    });
  };

  const uploadImage = (e) => {
    e.preventDefault();
    if (!profileImage) return;

    setLoading(true);

    const formData = new FormData();
    formData.append('profileImage', profileImage);

    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
        'Content-Type': 'multipart/form-data'
      },
      url: `${BASE_URL}/user/profile/uploadImage`,
      method: 'POST',
      data: formData
    }).then((res) => {
      setLoading(false);
      fetchProfileImage();
      setButhide(false)
    }).catch((err) => {
      if (err?.response?.data?.message) {
        setError(err?.response?.data?.message);
        setButhide(false)
        setTimeout(() => {
          setError("");
        }, 3000);
      }
      setLoading(false);
    });
  };

  const getProfile = () => {
    setError("");
    setProfileLoading(true);
    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/user/profile`,
      method: 'GET'
    }).then((res) => {
      const userData = res?.data?.data;
      const [firstName, lastName] = userData.name.split(" ");
      setUser({
        ...userData,
        firstName,
        lastName
      });
      setUserForm({
        ...userData,
        firstName,
        lastName
      });
      setProfileLoading(false);
    }).catch((err) => {
      if (err?.response?.data?.message) {
        setError(err?.response?.data?.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
      setProfileLoading(false);
    });
  };

  const fetchProfileImage = () => {
    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/user/profile/image`,
      method: 'GET',
      responseType: 'blob'
    }).then((res) => {
      const url = URL.createObjectURL(res.data);
      setImageURL(url)
    }).catch((err) => {
      if (err?.response?.data?.message) {
        setError(err?.response?.data?.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  const handleProfilePictureChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImageURL(URL.createObjectURL(e.target.files[0]));
      setButhide(true);
    }
  };

  return (
    <>
      <div className="edit-user-page sign-in-default">
        <div className="container">
          <span className="close-btn" onClick={() => { handlePopUp(0) }} >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </span>
          <div className="form-box-outer">
            <div className="form-box">
              {/* <h1>Edit Profile Page</h1> */}
              {profileLoading ? (
                <Skeleton count={5} className="mt-10" />
              ) : (
                <form onSubmit={updateProfile}>
                  <div className="input-box col-span-2 profile-box">
                    <div className="flex items-center gap-4 justify-center ">
                      <div className="relative" id="file">
                        {imageURL ? <img src={imageURL} alt="Profile" />:<img src={adminDumny} alt="Profile" />}
                        <div className="flex">
                          <input
                            type="file"
                            onChange={(e) => {
                              handleImageChange(e);
                              handleProfilePictureChange(e);
                            }}
                          />
                          <button onClick={uploadImage} className={`py-1 px-3 rounded-lg text-white text-xs bg-[#2995F7] border-none ${buthide  ? "" : "hidden"}`}>Confirm</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input-box">
                    <div className="heading">First Name</div>
                    <input
                    className="placeholder:text-black"
                      value={userForm.firstName}
                      onChange={handleInputChange}
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder="Name"
                    />
                  </div>
                  <div className="input-box">
                    <div className="heading">Last Name</div>
                    <input
                    className="placeholder:text-black"
                      value={userForm.lastName}
                      onChange={handleInputChange}
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="input-box">
                    <div className="heading">Age</div>
                    <input
                    className="placeholder:text-black"
                      value={userForm.age}
                      onChange={handleInputChange}
                      id="age"
                      name="age"
                      type="number"
                      placeholder="age "
                    />
                     <span className="input-after">year</span>
                  </div>
                  <div className="input-box radio-box">
                    <div className="heading">Gender</div>
                    <div>
                      <label>
                        <input
                        className="placeholder:text-black"
                          type="radio"
                          name="gender"
                          value="male"
                          checked={userForm.gender === "male"}
                          onChange={handleGenderChange}
                        />
                        Male
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          value="female"
                          checked={userForm.gender === "female"}
                          onChange={handleGenderChange}
                        />
                        Female
                      </label>
                    </div>
                  </div>
                  <div className="input-box">
                    <div className="heading">Weight</div>
                    <input
                    className="placeholder:text-black"
                      value={userForm.weight}
                      onChange={handleInputChange}
                      id="weight"
                      name="weight"
                      type="text"
                      placeholder="Weight "
                    />
                      <span className="input-after">Kg</span>
                  </div>
                  <div className="input-box">
                    <div className="heading">Height</div>
                    <input
                    className="placeholder:text-black"
                      value={userForm.height}
                      onChange={handleInputChange}
                      id="height"
                      name="height"
                      type="number"
                      placeholder="Height "
                      />
                      <span className="input-after">cm</span>
                  </div>
                  <div className="submit-box">
                    <button type="submit">Update</button>
                    <button type="button" className="cancel-btn" onClick={() => handlePopUp(0)}>Cancel</button>
                  </div>
                  {loading && <Skeleton count={5} />}
                  {error && <div className="error-message">{error}</div>}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfilebyUsrPopUp;


