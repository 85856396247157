import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Modal = ({ modalShow, onModalConfirm, setModalShow, title, content, deviceType, notShowFooter }) => {

    return (
        <>
            <div id="defaultModal" tabindex="-1" aria-hidden="true" class={`fixed flex justify-center bg-gray-900 bg-opacity-90 pt-[100px] z-50 w-full p-4 overflow-x-hidden ${!modalShow && 'hidden'} overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div class="relative w-full max-w-3xl max-h-full">
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 p-2">
                        <div class="flex items-start justify-between p-4 b rounded-t dark:border-gray-600">
                            <div class="text-xl text-black dark:text-white font-medium">
                                {title}
                            </div>
                            {/* <button onClick={() => setModalShow(false)} type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span class="sr-only">Close modal</span>
                            </button> */}
                        </div>
                        <div class="px-5 space-y-6 flex">
                            <p class="text-base leading-relaxed text-black dark:text-gray-400">
                                {content}
                            </p>
                        </div>
                        {!notShowFooter && (
                            <div class="flex justify-center p-6 space-x-2 rounded-lg dark:border-gray-600">
                                <button onClick={onModalConfirm} data-modal-hide="defaultModal" type="button" className="mx-4 font-medium rounded-lg text-sm px-10 py-2.5 text-center hover:text-gray-900 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 bg-[#186CED] text-white border-[#186CED]">YES</button>
                                <button onClick={() => setModalShow(false)} data-modal-hide="defaultModal" type="button" className="mx-8 rounded-lg text-sm font-medium px-10 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 border-[#186CED] bg-white text-[#186CED]">NO</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
export default Modal;


