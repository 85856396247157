import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL, FITBIT_CLIENT_ID } from "../../config";
import cftLogo from "../../assets/images/cftLogo.svg";
import openArrow from "../../assets/images/openArrow.svg";
import navIcon1 from "../../assets/images/navIcon-1.svg";
import navIcon2 from "../../assets/images/navIcon-2.svg";
import navIcon3 from "../../assets/images/navIcon-3.svg";
import navIcon4 from "../../assets/images/navIcon-4.svg";
import navIcon5 from "../../assets/images/navIcon-5.svg";
import navIcon6 from "../../assets/images/navIcon-6.svg";
import navIcon7 from "../../assets/images/navIcon-7.svg";
import navIcon8 from "../../assets/images/navIcon-8.svg";
import navIcon9 from "../../assets/images/navIcon-9.svg";

const SideBar = ({ isSidebarOpen, parentToChildSideBAr, userType, howerRefresh, userInfo }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [role, setRole] = useState("user");
    const [walkThroughPending, setWalkThroughPending] = useState(JSON.parse(localStorage.getItem("walkthough")).walkThroughPending);
    const [walkThroughSteps, setWalkThroughSteps] = useState(JSON.parse(localStorage.getItem("walkthough")).walkThroughSteps);
    const [howerActiveClass, setHowerActiveClass] = useState("Device");
    const [garConnect, setGarConnect] = useState(false);
    const [fitConnect, setFitConnect] = useState(false);
    const [gartokConnect, setGartokConnect] = useState(false);
    const [filterval, setFilterval] = useState(false);
    const [cou,setCou]=useState(0);
    useEffect(() => {
        setRole(userType);
    }, [userType]);

    const handleMenuClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const handleContentDatafunction = (prop) => {
       
        setHowerActiveClass(prop);
        parentToChildSideBAr(prop);
    }

    const handleFunctionForAdmin = (prop) => {
        setHowerActiveClass(prop);
        parentToChildSideBAr(prop);
    }

    const toggleSubMenu = () => {
        setIsSubMenuVisible(!isSubMenuVisible);
    };
    const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

    useEffect(() => {
        // if(howerRefresh)
        setHowerActiveClass(howerRefresh);
    }, [howerRefresh]);

    const getDeviceStatus = async () => {
        try {
            const res = await axios({
                headers: {
                    "x-access-token": localStorage.getItem("auth-token"),
                },
                url: `${BASE_URL}/user/device/status`,
                method: "GET",
            });

            // console.log("functionc all    59  ")
            setGarConnect(res.data.isGarminConnected);
            setFitConnect(res.data.isFitbitConnected);
            setGartokConnect(res.data.isGarminConnectedthroughtoken);
            // console.log(res.data.isGarminConnected)
        } catch (err) {
            if (err.code === "ERR_BAD_REQUEST") {
                // Handle error
            }
        }
    };

    useEffect(() => {
        getDeviceStatus();
    }, []);

    useEffect(() => {
        if (fitConnect || garConnect ) {
            // if (!walkThroughPending && walkThroughSteps == 2) {
                setFilterval(true);
            // } else {
            //     setFilterval(false);
            //     setHowerActiveClass("device")
            // }
        } else {
            setFilterval(false); 
            setHowerActiveClass("device")
        }
    }, [fitConnect, garConnect, gartokConnect, walkThroughPending, walkThroughSteps]);


       useEffect(()=>{
     const interval=setInterval(()=>{
       setCou(prevCou=>prevCou+1);
    // console.log("uuuuuuuuuuuuuu"  , garConnect)
    getDeviceStatus();
    // if (fitConnect || garConnect ) {
        // if (!walkThroughPending && walkThroughSteps == 2) {
            // setFilterval(true);
        // } else {
            // setFilterval(false);
            // setHowerActiveClass("device")
        // }
    // } else {
        // setFilterval(false); 
        // setHowerActiveClass("device")
    // }
    // console.log("function call :   89")
        },2000);
     
    //    return ()=>clearInterval(interval)

      },[]);
      useEffect(() => {
        const checkUrlAndSetActiveClass = () => {
            const currentPath = window.location.pathname;
            const targetPath = "/services/pament/confirm";
            const targetPath1 = "/booked/sessions";
            if (currentPath.endsWith(targetPath)) {
                setHowerActiveClass("Training");
            }else if(currentPath.endsWith(targetPath1)){
                // console.log(currentPath.endsWith(targetPath1))
                setHowerActiveClass("Training");
            }
        };

        checkUrlAndSetActiveClass();

        const handlePopState = () => {
            checkUrlAndSetActiveClass();
        };
        
        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);
    return (
        <div className={`sidebar ${isSidebarOpen ? 'slide' : ''}`}>
            <div className="sticky top-8">
                <div className="logo">
                    <img src={cftLogo} alt="logo" />
                    <h1>Contingent Fitness</h1>
                </div>
                <div className="menu">
                    {role === "user" && (
                        <>
                            <div className={`menu-item ${activeIndex === 0 ? 'active' : ''}`}>

                                <div className="menu-title" onClick={() => handleMenuClick(0)}>

                                    <div>
                                        <span className="hide-text-m">User Dashboard</span>
                                    </div>
                                    <div className="menu-btn">
                                        <img src={openArrow} alt="button" />
                                    </div>
                                </div>
                                <div className="sub-menu">
                                    <div className={`sub-head ${howerActiveClass === "device" ? "active" : ""}`} onClick={() => (userInfo?.walkThroughSteps !== "0" || userInfo?.isCompanyUser) && handleContentDatafunction("device")}>
                                        <img src={navIcon1} alt="Icon" />
                                        <span className="hide-text-m">Device and Apps</span>
                                    </div>
                                    <div className={`sub-head ${howerActiveClass === "dashboard" ? "active" : ""}  ${!filterval ? "hidenfun" : ""}`} onClick={() => !userInfo?.walkThroughPending && !["0", "1"].includes(userInfo?.walkThroughSteps) && handleContentDatafunction("dashboard")}>
                                        <img src={navIcon2} alt="Icon" />
                                        <span className="hide-text-m">Calendar</span>
                                    </div>
                                    <div className={`sub-head ${howerActiveClass === "summary-goals" ? "active" : ""}  ${!filterval ? "hidenfun" : ""}`} onClick={() => !userInfo?.walkThroughPending && handleContentDatafunction("summary-goals")}>
                                        <img src={navIcon3} alt="Icon" />
                                        <span className="hide-text-m">Summary and Goals</span>
                                    </div>
                                    <div className={`sub-head ${howerActiveClass === "healthyContent" ? "active" : ""}`} onClick={() => (userInfo?.userStatus === 2 || !userInfo?.walkThroughPending) && handleContentDatafunction("healthyContent")}>
                                        <img src={navIcon5} alt="Icon" />
                                        <span className="hide-text-m">Healthy Content</span>
                                    </div>
                                    <div className={`sub-head ${howerActiveClass === "forums" ? "active" : ""}`} onClick={() => (userInfo?.userStatus === 2 || !userInfo?.walkThroughPending) && handleContentDatafunction("forums")}>
                                        <img src={navIcon6} alt="Icon" />
                                        <span className="hide-text-m">Forums</span>
                                    </div>
                                    {(!userInfo?.companyDetails || userInfo?.isCompanyAdmin) && (
                                        <div className={`sub-head ${howerActiveClass === "membership" ? "active" : ""}`} onClick={() => handleContentDatafunction("membership")}>
                                            <img src={navIcon7} alt="Icon" />
                                            <span className="hide-text-m">Membership</span>
                                        </div>
                                    )}
                                    <div className={`sub-head ${howerActiveClass === "Training" ? "active" : ""}`} onClick={() => !userInfo?.walkThroughPending && handleContentDatafunction("Training")}>
                                        <img src={navIcon8} alt="Icon" />
                                        <span className="hide-text-m">Training</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {role === "admin" && (
                        <>
                            <div className={`menu-item ${activeIndex === 0 ? 'active' : ''}`}>
                                <div className="menu-title" onClick={() => handleMenuClick(0)}>
                                    <div>
                                        <span className="hide-text-m">User Dashboard</span>
                                    </div>
                                    <div className="menu-btn">
                                        <img src={openArrow} alt="button" />
                                    </div>
                                </div>
                                <div className="sub-menu">
                                    <div className={`sub-head ${howerActiveClass === "device" ? "active" : ""}`}
                                        onClick={() => (userInfo?.walkThroughSteps != "0" || userInfo?.isCompanyUser) && handleFunctionForAdmin("device")}>
                                        <img src={navIcon1} alt="Icon" />
                                        <span className="hide-text-m">Device and Apps</span>
                                    </div>

                                    <div className={`sub-head ${howerActiveClass === "dashboard" ? "active" : ""}  ${!filterval ? "hidenfun" : ""}`}

                                        onClick={() => !userInfo?.walkThroughPending && !["0", "1"].includes(userInfo?.walkThroughSteps) && handleFunctionForAdmin("dashboard")}>
                                        <img src={navIcon2} alt="Icon" />
                                        <span className="hide-text-m">Calendar</span>
                                    </div>

                                    <div className={`sub-head ${howerActiveClass === "summary-goals" ? "active" : ""}  ${!filterval ? "hidenfun" : ""}`}

                                        onClick={() => !userInfo?.walkThroughPending && handleFunctionForAdmin("summary-goals")}>
                                        <img src={navIcon3} alt="Icon" />
                                        <span className="hide-text-m">Summary and Goals</span>
                                    </div>
                                    <div className={`sub-head ${howerActiveClass === "leaderboard" ? "active" : ""}`}
                                        onClick={() => !userInfo?.walkThroughPending && handleFunctionForAdmin("leaderboard")}>
                                        <img src={navIcon4} alt="Icon" />
                                        <span className="hide-text-m">Leaderboard</span>
                                    </div>
                                    <div className={`sub-head ${howerActiveClass === "forum" ? "active" : ""}`}
                                        onClick={() => (userInfo?.userStatus == 2 || !userInfo?.walkThroughPending) && handleFunctionForAdmin("forum")}>
                                        <img src={navIcon6} alt="Icon" />
                                        <span className="hide-text-m">Forum</span>
                                    </div>
                                    <div className={`sub-head ${howerActiveClass === "healthyContent" ? "active" : ""}`}
                                        onClick={() => (userInfo?.userStatus == 2 || !userInfo?.walkThroughPending) && handleFunctionForAdmin("healthyContent")}>
                                        <img src={navIcon5} alt="Icon" />
                                        <span className="hide-text-m">Healthy Content</span>
                                    </div>


                                    {/* {(!userInfo?.companyDetails || userInfo?.isCompanyAdmin) && <div className={`sub-head ${howerActiveClass === "membership" ? "active" : ""}`}
                                        onClick={() => handleFunctionForAdmin("membership")}>
                                        <img src={navIcon7} alt="Icon" />
                                        <span className="hide-text-m">Membership</span>
                                    </div>} */}

                                    <div className={`sub-head ${howerActiveClass === "Training" ? "active" : ""}`}
                                        onClick={() => !userInfo?.walkThroughPending && handleFunctionForAdmin("Training")}>
                                        <img src={navIcon8} alt="Icon" />
                                        <span className="hide-text-m">Training</span>
                                    </div>
                                </div>
                            </div>
                            <div className={`menu-item ${activeIndex === 1 ? 'active' : ''}`}>
                                <div className="menu-title" onClick={() => handleMenuClick(1)}>
                                    <div>

                                        <span className="hide-text-m">Super Admin Dashboard</span>

                                    </div>
                                    <div className="menu-btn">
                                        <img src={openArrow} alt="button" />
                                    </div>
                                </div>
                                <div className="sub-menu">

                                    <div className={`sub-head ${howerActiveClass === "forums" ? "active" : ""}`}
                                        onClick={() => (userInfo?.userStatus == 2 || !userInfo?.walkThroughPending) && handleFunctionForAdmin("forums")}>
                                        <img src={navIcon6} alt="Icon" />
                                        <span className="hide-text-m">Forum</span>
                                    </div>
                                    <div className={`sub-head ${howerActiveClass === "leaderboard" ? "active" : ""}`}
                                        onClick={() => !userInfo?.walkThroughPending && handleFunctionForAdmin("leaderboard")}>
                                        <img src={navIcon4} alt="Icon" />
                                        <span className="hide-text-m">Leaderboard</span>
                                    </div>
                                    <div className={`sub-menu-item ${isSubMenuVisible ? 'active' : ''}`}>
                                        <div className="sub-title" onClick={toggleSubMenu}>
                                            <div className="sub-head">
                                                <span className="hide-text-m">
                                                    <img src={navIcon8} alt="Icon" /> 
                                                    Training
                                                </span>
                                            </div>
                                            <div className="menu-btn">
                                                <img src={openArrow} alt="button" />
                                            </div>
                                        </div>
                                        <div className="sub-menu">

                                            <div className={`sub-head ${howerActiveClass === "training-slots" ? "active" : ""}`}
                                            onClick={() => handleFunctionForAdmin("training-slots")}>
                                                {/* <img src={navIcon1} alt="Icon" /> */}
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-6 w-4 mr-2 align-middle">
                                                <path d="M5.25 12a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H6a.75.75 0 0 1-.75-.75V12ZM6 13.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V14a.75.75 0 0 0-.75-.75H6ZM7.25 12a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H8a.75.75 0 0 1-.75-.75V12ZM8 13.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V14a.75.75 0 0 0-.75-.75H8ZM9.25 10a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H10a.75.75 0 0 1-.75-.75V10ZM10 11.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V12a.75.75 0 0 0-.75-.75H10ZM9.25 14a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H10a.75.75 0 0 1-.75-.75V14ZM12 9.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V10a.75.75 0 0 0-.75-.75H12ZM11.25 12a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H12a.75.75 0 0 1-.75-.75V12ZM12 13.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V14a.75.75 0 0 0-.75-.75H12ZM13.25 10a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H14a.75.75 0 0 1-.75-.75V10ZM14 11.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V12a.75.75 0 0 0-.75-.75H14Z" />
                                                <path fill-rule="evenodd" d="M5.75 2a.75.75 0 0 1 .75.75V4h7V2.75a.75.75 0 0 1 1.5 0V4h.25A2.75 2.75 0 0 1 18 6.75v8.5A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25v-8.5A2.75 2.75 0 0 1 4.75 4H5V2.75A.75.75 0 0 1 5.75 2Zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75Z" clip-rule="evenodd" />
                                                </svg>
                                                <span className="hide-text-m">Avalibility Calander</span>
                                            </div>
                                            <div className={`sub-head ${howerActiveClass === "BookedTrainingSlots" ? "active" : ""}`}
                                                onClick={() => (userInfo?.userStatus == 2 || !userInfo?.walkThroughPending) && handleFunctionForAdmin("BookedTrainingSlots")}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-6 w-4 mr-2 align-middle">
                                                    <path d="M14 17h2.75A2.25 2.25 0 0 0 19 14.75v-9.5A2.25 2.25 0 0 0 16.75 3H14v14ZM12.5 3h-5v14h5V3ZM3.25 3H6v14H3.25A2.25 2.25 0 0 1 1 14.75v-9.5A2.25 2.25 0 0 1 3.25 3Z" />
                                                </svg>
                                                <span className="hide-text-m">Booked Slots</span>
                                            </div>
                                            <div className={`sub-head ${howerActiveClass === "Managed/TrainingSession" ? "active" : ""}`}
                                                onClick={() => handleFunctionForAdmin("Managed/TrainingSession")}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-6 w-4 mr-2 align-middle">
                                                    <path fillRule="evenodd" d="M.99 5.24A2.25 2.25 0 0 1 3.25 3h13.5A2.25 2.25 0 0 1 19 5.25l.01 9.5A2.25 2.25 0 0 1 16.76 17H3.26A2.267 2.267 0 0 1 1 14.74l-.01-9.5Zm8.26 9.52v-.625a.75.75 0 0 0-.75-.75H3.25a.75.75 0 0 0-.75.75v.615c0 .414.336.75.75.75h5.373a.75.75 0 0 0 .627-.74Zm1.5 0a.75.75 0 0 0 .627.74h5.373a.75.75 0 0 0 .75-.75v-.615a.75.75 0 0 0-.75-.75H11.5a.75.75 0 0 0-.75.75v.625Zm6.75-3.63v-.625a.75.75 0 0 0-.75-.75H11.5a.75.75 0 0 0-.75.75v.625c0 .414.336.75.75.75h5.25a.75.75 0 0 0 .75-.75Zm-8.25 0v-.625a.75.75 0 0 0-.75-.75H3.25a.75.75 0 0 0-.75.75v.625c0 .414.336.75.75.75H8.5a.75.75 0 0 0 .75-.75ZM17.5 7.5v-.625a.75.75 0 0 0-.75-.75H11.5a.75.75 0 0 0-.75.75V7.5c0 .414.336.75.75.75h5.25a.75.75 0 0 0 .75-.75Zm-8.25 0v-.625a.75.75 0 0 0-.75-.75H3.25a.75.75 0 0 0-.75.75V7.5c0 .414.336.75.75.75H8.5a.75.75 0 0 0 .75-.75Z" clipRule="evenodd" />
                                                </svg>
                                                <span className="hide-text-m">Manage Training </span>
                                            </div>

                                        </div>
                                    </div>


                                    <div className={`sub-head ${howerActiveClass === "company/report" ? "active" : ""}`}
                                        onClick={() => handleFunctionForAdmin("company/report")}>
                                        {/* <img src={navIcon2} alt="Icon" /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-6 w-4 mr-2 align-middle">
  <path fill-rule="evenodd" d="M4.5 2A1.5 1.5 0 0 0 3 3.5v13A1.5 1.5 0 0 0 4.5 18h11a1.5 1.5 0 0 0 1.5-1.5V7.621a1.5 1.5 0 0 0-.44-1.06l-4.12-4.122A1.5 1.5 0 0 0 11.378 2H4.5Zm2.25 8.5a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Zm0 3a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z" clip-rule="evenodd" />
</svg>

                                        <span className="hide-text-m">Report</span>
                                    </div>

                                    <div className={`sub-head ${howerActiveClass === "users" ? "active" : ""}`}
                                        onClick={() => handleFunctionForAdmin("users")}>
                                        {/* <img src={navIcon3} alt="Icon" /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6 w-5 mr-2 align-middle">
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                                        </svg>
                                        <span className="hide-text-m">User</span>
                                    </div>

                                
                                    <div className={`sub-head ${howerActiveClass === "coupons" ? "active" : ""}`}
                                        onClick={() => handleFunctionForAdmin("coupons")}>
                                        
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"  className="size-6 w-4 mr-2 align-middle">
  <path fill-rule="evenodd" d="M1 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V6Zm4 1.5a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2 3a4 4 0 0 0-3.665 2.395.75.75 0 0 0 .416 1A8.98 8.98 0 0 0 7 14.5a8.98 8.98 0 0 0 3.249-.604.75.75 0 0 0 .416-1.001A4.001 4.001 0 0 0 7 10.5Zm5-3.75a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.75-.75Zm0 6.5a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.75-.75Zm.75-4a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5Z" clip-rule="evenodd" />
</svg> */}
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"  className="size-6 w-4 mr-2 align-middle">
  <path fill-rule="evenodd" d="M15.75 3A2.25 2.25 0 0 1 18 5.25v1.214c0 .423-.277.788-.633 1.019A2.997 2.997 0 0 0 16 10c0 1.055.544 1.982 1.367 2.517.356.231.633.596.633 1.02v1.213A2.25 2.25 0 0 1 15.75 17H4.25A2.25 2.25 0 0 1 2 14.75v-1.213c0-.424.277-.789.633-1.02A2.998 2.998 0 0 0 4 10a2.997 2.997 0 0 0-1.367-2.517C2.277 7.252 2 6.887 2 6.463V5.25A2.25 2.25 0 0 1 4.25 3h11.5ZM13.5 7.396a.75.75 0 0 0-1.5 0v1.042a.75.75 0 0 0 1.5 0V7.396Zm0 4.167a.75.75 0 0 0-1.5 0v1.041a.75.75 0 0 0 1.5 0v-1.041Z" clip-rule="evenodd" />
</svg>

                                        <span className="hide-text-m">Coupon</span>
                                    </div>
                                    <div className={`sub-head ${howerActiveClass === "Managed/MamberShip" ? "active" : ""}`}
                                        onClick={() => handleFunctionForAdmin("Managed/MamberShip")}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 w-4 mr-2 align-middle">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                                        </svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"  className="size-6 w-4 mr-2 align-middle">
  <path fill-rule="evenodd" d="M1 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V6Zm4 1.5a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2 3a4 4 0 0 0-3.665 2.395.75.75 0 0 0 .416 1A8.98 8.98 0 0 0 7 14.5a8.98 8.98 0 0 0 3.249-.604.75.75 0 0 0 .416-1.001A4.001 4.001 0 0 0 7 10.5Zm5-3.75a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.75-.75Zm0 6.5a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.75-.75Zm.75-4a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5Z" clip-rule="evenodd" />
</svg>

                                        <span className="hide-text-m"> Membership</span>
                                    </div>

                                    <div className={`sub-head ${howerActiveClass === "wellnessprogram" ? "active" : ""}`}
                                        onClick={() => handleFunctionForAdmin("wellnessprogram")}>
                                        <img src={navIcon5} alt="Icon" />
                                        <span className="hide-text-m">Wellness Program</span>
                                    </div>

                                    {/* <div className={`sub-head ${howerActiveClass === "leaderboard" ? "active" : ""}`}
                                    onClick={() => handleFunctionForAdmin("Device")}>
                                        <img src={navIcon6} alt="Icon" />
                                        <span className="hide-text-m">Forums</span>
                                    </div>

                                    <div className={`sub-head ${howerActiveClass === "leaderboard" ? "active" : ""}`}
                                    onClick={() => handleFunctionForAdmin("Device")}>
                                        <img src={navIcon7} alt="Icon" />
                                        <span className="hide-text-m">Membership</span>
                                    </div>

                                    <div className={`sub-head ${howerActiveClass === "leaderboard" ? "active" : ""}`}
                                    onClick={() => handleFunctionForAdmin("Device")}>
                                        <img src={navIcon8} alt="Icon" />
                                        <span className="hide-text-m">Training</span>
                                    </div> */}

                                </div>
                            </div>
                        </>
                    )}
                    {role === "company" && (
                        <>
                            <div className={`menu-item ${activeIndex === 0 ? 'active' : ''}`}>
                                <div className={`menu-title`} onClick={() => handleMenuClick(0)}>
                                    <div className=" false">
                                        <span className="hide-text-m"> User Dashboard</span>
                                    </div>
                                    <div className="menu-btn">
                                        <img src={openArrow} alt="button" />
                                    </div>
                                </div>
                                <div className="sub-menu">
                                    <div
                                        className={`sub-head   ${howerActiveClass === "device" ? "active" : ""}`}
                                        onClick={() => handleContentDatafunction("device")}
                                    >
                                        <img src={navIcon1} alt="Icon" />
                                        <span className="hide-text-m">Device and Apps</span>
                                    </div>

                                    <div className={`sub-head   ${howerActiveClass === "dashboard" ? "active" : ""} ${!filterval ? "hidenfun" : ""}`}

                                        onClick={() => handleContentDatafunction("dashboard")}>
                                        <img src={navIcon2} alt="Icon" />
                                        <span className="hide-text-m">Calendar</span>
                                    </div>

                                    <div className={`sub-head   ${howerActiveClass === "summary-goals" ? "active" : ""} ${!filterval ? "hidenfun" : ""}`}

                                        onClick={() => handleContentDatafunction("summary-goals")}>
                                        <img src={navIcon3} alt="Icon" />
                                        <span className="hide-text-m">Summary and Goals</span>
                                    </div>
                                    {/* <div className={`sub-head   ${howerActiveClass === "leaderboard" ? "active" : ""}`}
                                        onClick={() => handleContentDatafunction("leaderboard")}>
                                        <img src={navIcon4} alt="Icon" />
                                        <span className="hide-text-m">Leaderboard</span>
                                    </div> */}
                                    <div className={`sub-head   ${howerActiveClass === "healthyContent" ? "active" : ""}`}
                                        onClick={() => handleContentDatafunction("healthyContent")}>
                                        <img src={navIcon5} alt="Icon" />
                                        <span className="hide-text-m">Healthy Content</span>
                                    </div>
                                    <div className={`sub-head   ${howerActiveClass === "forums" ? "active" : ""}`}
                                        onClick={() => handleContentDatafunction("forums")}>
                                        <img src={navIcon6} alt="Icon" />
                                        <span className="hide-text-m">Forums</span>
                                    </div>
                                    {/* <div className={`sub-head ${howerActiveClass === "membership" ? "active" : ""}`}
                                        onClick={() => handleContentDatafunction("membership")}>
                                        <img src={navIcon7} alt="Icon" />
                                        <span className="hide-text-m">Membership</span>
                                    </div> */}
                                    <div className={`sub-head  ${howerActiveClass === "Training" ? "active" : ""}`}
                                        onClick={() => handleContentDatafunction("Training")}>
                                        <img src={navIcon8} alt="Icon" />
                                        <span className="hide-text-m">Training</span>
                                    </div>
                                </div>
                                <div className={`menu-item ${activeIndex === 1 ? 'active' : ''}`}>
                                <div className="menu-title" onClick={() => handleMenuClick(1)}>
                                    <div>

                                        <span className="hide-text-m">Company  Dashboard</span>

                                    </div>
                                    <div className="menu-btn">
                                        <img src={openArrow} alt="button" />
                                    </div>
                                </div>
                        <div className="sub-menu">
                                <div className={`sub-head ${howerActiveClass === "Company/User" ? "active" : ""}`}
                                        onClick={() => handleFunctionForAdmin("Company/User")}>
                                        <img src={navIcon3} alt="Icon" />
                                        <span className="hide-text-m">Company Users</span>
                                    </div> 
                                     <div className={`sub-head ${howerActiveClass === "Company/Report" ? "active" : ""}`}
                                        onClick={() => handleContentDatafunction("Company/Report")}>
                                        <img src={navIcon7} alt="Icon" />
                                        <span className="hide-text-m">Report</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SideBar;
